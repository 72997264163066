<template>
	<div class="home-wrap">
		<div class="home-head">
			<div class="head-main">
				<div class="logo"><img src="~@/assets/images/logo-278-61.png" alt=""></div>
				<div class="search">
					<div class="item w360">
						<el-input size="small">
							<el-button slot="append" size="small">搜本站</el-button>
						</el-input>
					</div>
					<div class="item"><el-button type="danger" size="small">搜本店</el-button></div>
				</div>
			</div>
		</div>
		<div class="home-main">
			<div class="goods-info">
				<div class="info-summary">
					<div class="summary-left">
						<div class="gallery">
							<image-magnifier :src="image" :zoom-src="image" width="400" height="400" zoom-width="400" zoom-height="400"></image-magnifier>
							<div class="gallery-thumbs">
								<div class="item"
									v-for="(item,index) in thumbs"
									:key="index"
									:class="{active: activeThumb(index)}"
									@mouseover="thumbChange(index)"
								>
									<img :src="item" alt="">
								</div>
							</div>
						</div>
						<div class="collect">
							<a href="javascript:void(0);">
								<i class="el-icon-star-on"></i>
								收藏宝贝(1307人气)
							</a>
						</div>
					</div>
					<div class="summary-right">
						<div class="title">
							<h3>sony索尼a7m4 ILCE-7M4 新品全画幅微单专业高清直播数码相机A7M4</h3>
						</div>
						<div class="item item-price">
							<div class="item-label">价格</div>
							<div class="item-box">
								<div class="price">¥<span>17000.00-43950.00</span></div>
							</div>
							<div class="item-block">
								<div class="sales">
									<p><strong>63</strong></p>
									<p class="mt12">交易成功</p>
								</div>
							</div>
						</div>
						<div class="item item-coupon">
							<div class="item-label">优惠</div>
							<div class="item-block">
								<ul class="coupon">
									<li>
										<span>100元店铺优惠券，满39999元可用</span>
										<a href="javascript:void(0);">领取</a>
									</li>
								</ul>
							</div>
						</div>
						<div class="item">
							<div class="item-label">快递</div>
							<div class="item-block">免运费</div>
						</div>
						<div class="item-line"></div>
						<div class="item">
							<div class="item-label">颜色</div>
							<div class="item-block">
								<el-radio-group v-model="color" size="small">
									<el-radio label="1" border>备选项1</el-radio>
								</el-radio-group>
							</div>
						</div>
						<div class="item">
							<div class="item-label">尺寸</div>
							<div class="item-block">
								<el-radio-group v-model="size" size="small">
									<el-radio label="1" border>备选项1</el-radio>
									<el-radio label="2" border>备选项5</el-radio>
									<el-radio label="3" border>备选项2</el-radio>
									<el-radio label="4" border>备选项3</el-radio>
									<el-radio label="5" border>备选项4</el-radio>
								</el-radio-group>
							</div>
						</div>
						<div class="item">
							<div class="item-label">数量</div>
							<div class="item-block">
								<el-input-number v-model="num" :min="1" :max="10" size="small"></el-input-number>
							</div>
						</div>
					</div>
				</div>
				<div class="info-sidebar">
					<div class="store-info">
						<div class="info-wrap">
							<div class="name">帝都江伟数码相机店</div>
							<div class="text">掌柜：xxxx</div>
							<div class="line"></div>
							<div class="info-bd"></div>
							<div class="info-ft">
								<el-button type="info" plain size="mini">进入店铺</el-button>
								<el-button type="info" plain size="mini">收藏店铺</el-button>
							</div>
						</div>
					</div>
					<div class="recom-wrap">
						<div class="recom-head">
							<span class="name">看了又看</span>
							<i class="icon el-icon-refresh-right"></i>
						</div>
						<div class="recom-list">
							<a href="javascript:void(0);" class="item">
								<img class="pic" :src="image" alt="">
								<div class="price">￥<span>5550.00</span></div>
							</a>
							<a href="javascript:void(0);" class="item">
								<img class="pic" :src="image" alt="">
								<div class="price">￥<span>5550.00</span></div>
							</a>
							<a href="javascript:void(0);" class="item">
								<img class="pic" :src="image" alt="">
								<div class="price">￥<span>5550.00</span></div>
							</a>
						</div>
					</div>
				</div>
			</div>
			<el-tabs type="border-card">
				<el-tab-pane label="宝贝详情">用户管理</el-tab-pane>
				<el-tab-pane label="评 价">
					<div class="goods-evaluate">
						<div class="item">
							<div class="item-label">
								<el-avatar shape="square" icon="el-icon-user-solid"></el-avatar>
								<div class="name">zhangsan</div>
							</div>
							<div class="item-block">
								<div class="text">哎呦不错哦！</div>
								<div class="thumbs">
									<div class="item"><img :src="image" alt=""></div>
								</div>
								<div class="desc">
									<span>2022年05月03日 16:58</span>
									<span>颜色分类：索尼A7M4国行现货带增普票[现货当天发]  套餐类型：官方标配</span>
								</div>
							</div>
						</div>
					</div>
				</el-tab-pane>
			</el-tabs>
		</div>
	</div>
</template>

<script>
	import { ImageMagnifier } from 'vue-image-magnifier'
	export default {
		components: { ImageMagnifier },
		data() {
			return {
				color: '',
				size: '',
				num: 1,
				image: 'https://gd4.alicdn.com/imgextra/i2/57944630/O1CN01mM92v51k4aVXilbii_!!57944630.jpg_400x400.jpg',
				thumbs: [
					'https://gd4.alicdn.com/imgextra/i2/57944630/O1CN01mM92v51k4aVXilbii_!!57944630.jpg_400x400.jpg',
					'https://gd3.alicdn.com/imgextra/i3/57944630/O1CN01LiKwjF1k4aV3eKIim_!!57944630.jpg_400x400.jpg',
					'https://gd3.alicdn.com/imgextra/i3/57944630/O1CN01Y7jXBd1k4aUvqLxGv_!!57944630.jpg_400x400.jpg',
					'https://gd3.alicdn.com/imgextra/i3/57944630/O1CN01nwLdut1k4aVYR4VvH_!!57944630.jpg_400x400.jpg',
					'https://gd4.alicdn.com/imgextra/i4/57944630/O1CN01sY1zHd1k4aV2XRwkP_!!57944630.jpg_400x400.jpg'
				],
				active: 0
			}
		},
		methods: {
			thumbChange(index) {
				this.active = index
				this.image = this.thumbs[index]
			},
			activeThumb(index) {
				return this.active === index
			}
		},
	}
</script>

<style lang="scss" scoped>
	.home-head {
		padding-top: 10px;
		.head-main {
			width: 1140px;
			height: 60px;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			.logo {
				height: 38px;
				margin-top: 5px;
				img {
					height: 100%;
				}
			}
			.search {
				padding-top: 10px;
				.item {
					margin-left: 4px;
					display: inline-block;
					vertical-align: middle;
					&.w360 {
						width: 360px;
					}
				}
				::v-deep .el-input-group {
					.el-input__inner {
						border-color: #f40;
					}
					.el-input-group__append {
						color: #ffffff;
						border-color: #f40;
						background-color: #f40;
						&:hover {
							border-color: #f22b00;
							background-color: #f22b00;
						}
					}
				}
				::v-deep .el-button--danger {
					color: #f40;
					border-color: #ffb086;
					background-color: #ffe4d0;
					&:hover {
						background-color: #ffd9b3;
					}
				}
			}
		}
	}
	.home-main {
		width: 1140px;
		margin: 10px auto 0;
		.goods-info {
			display: flex;
			margin-bottom: 20px;
			border: 1px solid #E8E8E8;
			.info-summary {
				flex: 1;
				display: flex;
				border-right: 1px solid #e8e8e8;
				.summary-left {
					flex-shrink: 0;
					width: 400px;
					position: relative;
					padding: 20px 0 20px 20px;
					.gallery {
						margin-bottom: 18px;
						.gallery-thumbs {
							margin-top: 15px;
							.item {
								width: 50px;
								height: 50px;
								margin-right: 9px;
								display: inline-block;
								vertical-align: middle;
								border: 2px solid transparent;
								cursor: pointer;
								img {
									width: 100%;
									height: 100%;
									object-fit: cover;
								}
								&.active {
									border-color: #f40;
								}
							}
						}
					}
					.collect {
						color: #6C6C6C;
						line-height: 22px;
						.el-icon-star-on {
							color: #f7ba2a;
							font-size: 16px;
						}
					}
				}
				.summary-right {
					flex: 1;
					width: 0;
					padding: 0 20px 20px 0;
					margin-left: 18px;
					.title {
						padding: 20px 0 10px;
						h3 {
							font-size: 16px;
							line-height: 21px;
							font-weight: 700;
							color: #3c3c3c;
							min-height: 21px;
						}
					}
					.item {
						display: flex;
						padding: 5px 10px;
						color: #3c3c3c;
						line-height: 24px;
						.item-label {
							width: 60px;
							flex-shrink: 0;
							color: #6c6c6c;
						}
						.item-box {
							flex-shrink: 0;
							width: 285px;
							.price {
								color: #f40;
								font-size: 24px;
								font-weight: 700;
								padding-right: 5px;
							}
						}
						.item-block {
							flex: 1;
							.sales {
								display: inline-block;
								vertical-align: middle;
								padding-left: 9px;
								font-size: 12px;
								line-height: 1em;
								color: #6C6C6C;
								text-align: center;
								strong {
									font-weight: 400;
									font-size: 18px;
									line-height: 18px;
								}
								.mt12 {
									margin-top: 12px;
								}
							}
							.coupon {
								li {
									line-height: 25px;
									color: #3c3c3c;
								}
								a {
									color: #f40;
									margin-left: 5px;
									border-bottom: 1px dotted #f40;
								}
							}
							::v-deep .el-radio-group {
								.el-radio {
									margin: 0 5px 10px 0;
									.el-radio__input {
										display: none;
									}
									&.is-checked {
										border-color: #F40;
										.el-radio__label {
											color: #F40;
										}
									}
								}
							}
						}
						&.item-price {
							padding: 10px 10px;
							background-color: #FFF2E8;
							.item-block {
								text-align: right;
							}
						}
						&.item-coupon {
							padding: 10px 10px;
							background-color: #FFF9F5;
						}
					}
					.item-line {
						margin-bottom: 5px;
						border-top: 1px dotted #D9D9D9;
					}
				}
			}
			.info-sidebar {
				flex-shrink: 0;
				width: 200px;
				.store-info {
					top: -1px;
					right: 1px;
					width: 100%;
					position: relative;
					border: 1px solid #ffe2a3;
					.info-wrap {
						padding: 14px 10px 17px 20px;
						.name {
							color: #3c3c3c;
							font-weight: 600;
							height: 20px;
							line-height: 20px;
							margin-bottom: 5px;
						}
						.text {
							height: 20px;
							line-height: 20px;
							color: #666;
							margin-bottom: 5px;
						}
						.line {
							border-top: dotted 1px #E1E1E1;
						}
						.info-bd {
							margin-bottom: 16px;
						}
					}
				}
				.recom-wrap {
					padding: 17px 12px 0 15px;
					overflow: hidden;
					.recom-head {
						padding: 0 5px 11px;
						line-height: 16px;
						display: flex;
						align-items: center;
						justify-content: space-between;
						.name {
							font-size: 12px;
							font-weight: 700;
							color: #535353;
						}
						.icon {
							font-weight: 600;
							color: #999999;
							font-size: 14px;
							cursor: pointer;
						}
					}
					.recom-list {
						.item {
							width: 80px;
							float: left;
							margin-right: 5px;
							margin-bottom: 5px;
							.pic {
								width: 80px;
								height: 80px;
								object-fit: cover;
							}
							.price {
								color: #ff4805;
								text-align: center;
								line-height: 22px;
								span {
									font-weight: 600;
								}
							}
							&:nth-child(2n) {
								margin-right: 0;
							}
						}
					}
				}
			}
		}
		.goods-evaluate {
			.item {
				padding: 10px;
				display: flex;
				border-bottom: 1px solid #D4D4D4;
				.item-label {
					flex-shrink: 0;
					width: 100px;
					text-align: center;
					.name {
						font-size: 14px;
						line-height: 18px;
					}
				}
				.item-block {
					flex: 1;
					.text {
						font-size: 14px;
						line-height: 1.4;
						color: #3f3f3f;
						word-wrap: break-word;
					}
					.thumbs {
						margin-top: 10px;
						.item {
							width: 40px;
							height: 40px;
							border: 2px solid #f0f0f0;
							padding: 2px;
							display: inline-block;
							vertical-align: middle;
							margin-right: 6px;
							background-color: #ffffff;
							cursor: zoom-in;
							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
							}
							&:hover {
								border-color: #f60;
							}
						}
					}
					.desc {
						font-size: 12px;
						color: #B0B0B0;
						margin-top: 10px;
						span {
							margin-right: 20px;
						}
					}
				}
			}
		}
	}
</style>
<style>
	.image-magnifier__zoom {
		box-shadow: 0 0 0 1px #e5e5e5;
	}
</style>